/* Responsive */
@media only screen and (min-width: 1100px) {
  .container-fluid {
    padding: 0 30px !important;
  }
  .tree-map {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .tree-map {
    font-size: 20px;
  }
}
/* Override Ant Menu Style */
.ant-menu * {
  border-bottom: none !important;
}
.ant-menu li {
  text-transform: uppercase;
}
.ant-menu li.ant-menu-item-selected {
  border-bottom: 7px solid #35b6b4 !important;
}
.ant-menu-horizontal .ant-menu-item {
  top: -2px;
  font-weight: normal;
}
.ant-collapse-header {
  padding-left: 0 !important;
}
.am-drawer-sidebar {
  z-index: 3;
}
.am-drawer-overlay {
  z-index: 2;
}
.transparent-white {
  background: rgba(255, 255, 255, 0.9);
}
.transparent-white .ant-card-body {
  padding: 12px;
}
.just-white {
  background: white;
}
.just-white .ant-card-body {
  padding: 12px;
}
.am-drawer-content {
  padding-top: 0 !important;
}
.am-drawer-draghandle {
  background-color: transparent;
}
.recharts-tooltip-wrapper {
  opacity: 0.85;
}
.ant-breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Table */
.ant-table-thead > tr > th {
  background-color: transparent;
  font-size: large;
  color: #0033a1;
}
th.number,
td.number {
  text-align: left !important;
}
.ant-pagination {
  width: 100%;
  text-align: center;
  margin: 30px 0 !important;
}
.ant-pagination-item-active {
  color: white !important;
  background-color: #0033a1;
  border-color: white;
}
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item-active:hover {
  color: white !important;
}
tbody > tr > td {
  word-break: normal !important;
}
.ant-dropdown-menu-item {
  padding: 10px 20px;
}
.download-icon {
  width: 20px;
  height: 20px;
  fill: #0035ad;
  margin-bottom: -5px;
}
.share-icon {
  width: 20px;
  margin-bottom: -5px;
  fill: #0033a1;
  transition: fill 0.5s ease;
}
.ant-btn-circle:hover .share-icon {
  transition: fill 0.5s ease;
  fill: #35b6b4;
}
/* Prevent to auto zoom on mobile */
input,
textarea {
  font-size: 16px !important;
}
.flag-icon {
  order: 1;
  border: 1px solid rgba(220, 220, 220, 0.7);
  height: 44px;
  width: 58px;
}
.flag-icon span {
  vertical-align: top !important;
  width: 56px !important;
  height: 42px !important;
}
.tabsContainer .ant-tabs-tab {
  border: 1px solid #0000001f;
  color: #8b8b8b;
}
.tabsContainer .ant-tabs-nav .ant-tabs-tab-active {
  background: #0033a1;
  color: #fff;
  /* font-family: Helvetica Neue; */
  font-weight: 500;
  border: none !important;
}
.tabsContainer .ant-tabs-nav .ant-tabs-tab {
  padding: 11px 16px;
}
.tabsContainer {
  padding: 40px;
  padding-top: 10px;
}
.tabsContainer .ant-tabs {
  padding: 20px 40px;
}
.tabsContainer .ant-table-thead {
  background: #f5f6f8;
}
.tabsContainer th .ant-table-column-title {
  text-align: left;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.87;
  font-size: 14px;
  font-weight: 600;
}
.tabsContainer .ant-table-body td {
  text-align: left;
  font: normal normal normal 14px/20px Open Sans;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.87;
  font-size: 14px;
}
.tabsContainer .ant-tabs-bar.ant-tabs-top-bar {
  padding: 22px 22px 22px 22px;
  border: 1px solid #e8e8e8;
}
.tabsContainer .ant-tabs-nav .ant-tabs-tab::before {
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #0000001f;
}
.tabsContainer .ant-tabs-bar {
  margin-bottom: 0px;
}
.contact-card {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #0033a01a;
  border-radius: 4px;
  opacity: 1;
}
.projectContactTitle {
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}
.col-container .ant-card-head-title {
  color: var(--unnamed-color-262626);
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #262626;
  font-weight: 600;
  opacity: 1;
}
.col-container .ant-card-head {
  border-bottom: none !important;
}
.col-container .ant-card {
  background: #ffffff;
  border: none;
}
.contactValue {
  color: var(--unnamed-color-262626);
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}
.tabsContainer .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.tabsContainer .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  border: 1px solid #0000001f;
  border-top: none;
}
.tabsContainer .ant-table-body {
  padding: 0px;
}
.ant-progress-status-success .ant-progress-text {
  color: #0033a1;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #0033a1;
  border-radius: 0px;
}
.project-other-container {
  padding: 40px;
}
.project-other-container .projectOtherTitle {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #212121;
  opacity: 0.87;
  border-bottom: 1px solid #0000001f;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
.project-other-container p {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.87;
  margin-bottom: 4px;
}
.project-other-container .ant-descriptions-item-label,
.project-other-container .ant-descriptions-item-content {
  font-size: 13px;
}
.project-other-container .ant-descriptions-row > th,
.project-other-container .ant-descriptions-row > td {
  padding-bottom: 10px;
  display: block;
}
.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 2px solid #0033a1 !important;
  z-index: 2;
}
.tabsContainer .ant-progress-inner {
  border-radius: 0px;
}
.tabsContainer .ant-progress-status-success .ant-progress-text,
.tabsContainer .ant-progress-text {
  color: #0033a1;
  display: none;
}
.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #0033a1;
  border-radius: 0px;
}
.tabsContainer .ant-progress-outer {
  padding: 0px;
}
.tabsContainer .ant-progress-inner {
  background: #e8eaed;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orangeText {
  color: var(--unnamed-color-f27f6d);
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #f27f6d;
  opacity: 1;
  margin-bottom: 2px;
}
.greyBox {
  color: var(--unnamed-color-262626);
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  padding: 3px 5px;
  background-color: #f5f6f8;
  border: none;
  margin-bottom: 0px;
}
.project-other-container p.marginBottom {
  margin-bottom: 10px;
}
.howWeSpendContainer {
  padding: 40px !important;
  margin: 0px 0 40px;
}
.howWeSpendContainer .recharts-default-legend {
  text-align: left !important;
}
.howWeSpendContainer .recharts-legend-wrapper {
  display: flex;
  justify-content: center;
}
.howWeSpendContainer .recharts-default-legend li {
  display: inline-block !important;
  margin-bottom: 20px;
  margin-right: 20px !important;
}
.howWeSpendContainer .recharts-responsive-container {
  margin-bottom: 4rem;
}
.howWeSpendContainer .recharts-legend-item-text {
  text-align: left;
  font-size: 13px;
  color: #6a6a6a;
}
.recharts-tooltip-wrapper {
  font-size: 13px;
}
.tabsContainer .ant-table-body td {
  min-width: 10rem;
}
.ant-table {
  overflow-x: auto;
}
.tabsContainer th .sort-title {
  text-align: left;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.87;
  font-size: 14px;
  font-weight: 600;
}
